
//
//
// aos.js
//
// Initialises the animate on scroll plugin

import AOS from 'aos';

AOS.init({ once: true });
